import React from 'react'
import { Helmet } from 'react-helmet'

// COMPS
import Layout from 'layout/Layout'

import PhotoCard from 'components/Card/PhotoCard'

// ACTIONS
const Retorio = (props) => {
  return (
    <Layout>
      <div className="grid">
        <Helmet>
          <title>Retorio</title>
        </Helmet>

        <PhotoCard
          col="2"
        />

        <PhotoCard
          photo="https://cdn.dribbble.com/users/2670277/screenshots/5773022/star_4x.png"
          to="/space/inspiration"
        />
      </div>
    </Layout>
  )
}

export default Retorio;