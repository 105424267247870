import React from 'react'

// COMPS
import Layout from 'layout/Layout'
import PhotoCard from 'components/Card/PhotoCard'



// ACTIONS
const Inspiration = (props) => {
  return (
    <Layout showPhoto={false}>
      <div className="grid">
        <PhotoCard
          col="2"
          row="2"
          photo="https://cdn.dribbble.com/users/2670277/screenshots/5773022/star_4x.png"
        />
        <PhotoCard
          photo="https://cdn.dribbble.com/users/881900/screenshots/5770426/1-jan-post_4x.jpg"
        />
        <PhotoCard
          photo="https://cdn.dribbble.com/users/43342/screenshots/5770667/dribbble_jan_2019_062_dribbble_4x.jpg"
        />
        <PhotoCard
          photo="https://cdn.dribbble.com/users/1626229/screenshots/5771719/chasing_the_flow_wow.jpg"
        />
        <PhotoCard
          photo="https://cdn.dribbble.com/users/388052/screenshots/5771338/lion_sketch.jpg"
        />
        <PhotoCard
          photo="https://cdn.dribbble.com/users/1556251/screenshots/5773209/___4x.png"
        />
        
      </div>
    </Layout>
  )
}

export default Inspiration;