import React from 'react'
import Link from "./Link"

const HasLink = ({ to, children, ...props }) => {
  return (
    <>
      {
        to ? <Link to={to} {...props}>{children}</Link> : children
      }
    </>
  )
}

export default HasLink;