import React from 'react'
import Img from 'react-image'
import classNames from 'classnames'

// CSS
import './Image.scss'

// COMPS
import Loader from 'components/Loader/Loader'

// ACTIONS
const Image = (props) => {
  const classes = classNames({
    "Image": true,
    "has-center-top": props.top,
  })

  return (
    <div className={classes}>
      <Img
        src={props.src}
        loader={<Loader absolute spinner={props.spinner} />}
        unloader={<Loader absolute error spinner={props.spinner} />}
      />
    </div>
  )
}

Image.defaultProps = {
  src: "/images/background/leo.jpg",
  alt: "Nothing.",
  spinner: true,
  something: true,
}

export default Image;