import React from 'react'

// CSS


// COMPS
import Card from 'components/Card/Card'

// Card
const GraphCard = (props) => {
  return (
    <Card type="graph" {...props}>
      {props.children}
    </Card>
  )
}

// CardHeader.defaultProps = {
//   title: "Something"
// }

export default GraphCard;