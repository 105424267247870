export default [
  {
    month: "January",
    starts: 1,
    days: [
      {
        day: 1,
        active: false,
      },
      {
        day: 2,
        active: true,
        type: "Strength Training",
        calories: 552,
        time: 47,
      },
      {
        day: 3,
        active: false,
      },
      {
        day: 4,
        active: false,
      },
      {
        day: 5,
        active: false,
      },
      {
        day: 6,
        active: false,
      },
      {
        day: 7,
        active: true,
        type: "Strength Training",
        calories: 602,
        time: 55,
      },
      {
        day: 8,
        active: false,
      },
      {
        day: 9,
        planned: true,
        active: false,
      },
      {
        day: 10,
        active: false,
      },
      {
        day: 11,
        planned: true,
        active: false,
      },
      {
        day: 12,
        active: false,
      },
      {
        day: 13,
        planned: true,
        active: false,
      },
      {
        day: 14,
        planned: true,
        active: false,
      },
      {
        day: 15,
        active: false,
      },
      {
        day: 16,
        planned: true,
        active: false,
      },
      {
        day: 17,
        active: false,
      },
      {
        day: 18,
        planned: true,
        active: false,
      },
      {
        day: 19,
        active: false,
      },
      {
        day: 20,
        planned: true,
        active: false,
      },
      {
        day: 21,
        planned: true,
        active: false,
      },
      {
        day: 22,
        active: false,
      },
      {
        day: 23,
        planned: true,
        active: false,
      },
      {
        day: 24,
        active: false,
      },
      {
        day: 25,
        planned: true,
        active: false,
      },
      {
        day: 26,
        active: false,
      },
      {
        day: 27,
        planned: true,
        active: false,
      },
      {
        day: 28,
        planned: true,
        active: false,
      },
      {
        day: 29,
        active: false,
      },
      {
        day: 30,
        planned: true,
        active: false,
      }, {
        day: 31,
        active: false,
      },
    ]
  },
  {
    month: "February",
    starts: 4,
    days: [
      {
        day: 1,
        active: false,
      },
      {
        day: 2,
        active: false,
      },
      {
        day: 3,
        active: false,
      },
      {
        day: 4,
        active: false,
      },
      {
        day: 5,
        active: false,
      },
      {
        day: 6,
        active: false,
      },
      {
        day: 7,
        active: false,
      },
      {
        day: 8,
        active: false,
      },
      {
        day: 9,
        active: false,
      },
      {
        day: 10,
        active: false,
      },
      {
        day: 11,
        active: false,
      },
      {
        day: 12,
        active: false,
      },
      {
        day: 13,
        active: false,
      },
      {
        day: 14,
        active: false,
      },
      {
        day: 15,
        active: false,
      },
      {
        day: 16,
        active: false,
      },
      {
        day: 17,
        active: false,
      },
      {
        day: 18,
        active: false,
      },
      {
        day: 19,
        active: false,
      },
      {
        day: 20,
        active: false,
      },
      {
        day: 21,
        active: false,
      },
      {
        day: 22,
        active: false,
      },
      {
        day: 23,
        active: false,
      },
      {
        day: 24,
        active: false,
      },
      {
        day: 25,
        active: false,
      },
      {
        day: 26,
        active: false,
      },
      {
        day: 27,
        active: false,
      },
      {
        day: 28,
        active: false,
      },
    ]
  },
  {
    month: "March",
    starts: 4,
    days: [
      {
        day: 1,
        active: false,
      },
      {
        day: 2,
        active: false,
      },
      {
        day: 3,
        active: false,
      },
      {
        day: 4,
        active: false,
      },
      {
        day: 5,
        active: false,
      },
      {
        day: 6,
        active: false,
      },
      {
        day: 7,
        active: false,
      },
      {
        day: 8,
        active: false,
      },
      {
        day: 9,
        active: false,
      },
      {
        day: 10,
        active: false,
      },
      {
        day: 11,
        active: false,
      },
      {
        day: 12,
        active: false,
      },
      {
        day: 13,
        active: false,
      },
      {
        day: 14,
        active: false,
      },
      {
        day: 15,
        active: false,
      },
      {
        day: 16,
        active: false,
      },
      {
        day: 17,
        active: false,
      },
      {
        day: 18,
        active: false,
      },
      {
        day: 19,
        active: false,
      },
      {
        day: 20,
        active: false,
      },
      {
        day: 21,
        active: false,
      },
      {
        day: 22,
        active: false,
      },
      {
        day: 23,
        active: false,
      },
      {
        day: 24,
        active: false,
      },
      {
        day: 25,
        active: false,
      },
      {
        day: 26,
        active: false,
      },
      {
        day: 27,
        active: false,
      },
      {
        day: 28,
        active: false,
      },
      {
        day: 29,
        active: false,
      },
      {
        day: 30,
        active: false,
      },
      {
        day: 31,
        active: false,
      },
    ]
  },
  {
    month: "April",
    starts: 0,
    days: [
      {
        day: 1,
        active: false,
      },
      {
        day: 2,
        active: false,
      },
      {
        day: 3,
        active: false,
      },
      {
        day: 4,
        active: false,
      },
      {
        day: 5,
        active: false,
      },
      {
        day: 6,
        active: false,
      },
      {
        day: 7,
        active: false,
      },
      {
        day: 8,
        active: false,
      },
      {
        day: 9,
        active: false,
      },
      {
        day: 10,
        active: false,
      },
      {
        day: 11,
        active: false,
      },
      {
        day: 12,
        active: false,
      },
      {
        day: 13,
        active: false,
      },
      {
        day: 14,
        active: false,
      },
      {
        day: 15,
        active: false,
      },
      {
        day: 16,
        active: false,
      },
      {
        day: 17,
        active: false,
      },
      {
        day: 18,
        active: false,
      },
      {
        day: 19,
        active: false,
      },
      {
        day: 20,
        active: false,
      },
      {
        day: 21,
        active: false,
      },
      {
        day: 22,
        active: false,
      },
      {
        day: 23,
        active: false,
      },
      {
        day: 24,
        active: false,
      },
      {
        day: 25,
        active: false,
      },
      {
        day: 26,
        active: false,
      },
      {
        day: 27,
        active: false,
      },
      {
        day: 28,
        active: false,
      },
      {
        day: 29,
        active: false,
      },
      {
        day: 30,
        active: false,
      },
      {
        day: 31,
        active: false,
      },
    ]
  },
]