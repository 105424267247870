import React from 'react'
import Tippy from '@tippy.js/react'
import classNames from 'classnames'
import 'tippy.js/dist/tippy.css'

import Icon from 'components/Icon/Icon'

// DATA
import data from 'data/CalChartData.js'
import './Graph.scss'

const CalendarTooltip = (props) => {
  return(
    <div className="CalendarTooltip">
      <div className="title is-4">
        {props.type}
        {
          props.type &&
          <div className="CalendarTooltipIcon">
            {props.type === "Boxing" && <Icon.Boxing />}
            {props.type === "Strength Training" && <Icon.Strength />}
            {props.type === "Squash" && <Icon.Squash />}
          </div>
        }
        
      </div>
      <div className="subtitle">{props.calories} Calories</div>
      <div className="subtitle">{props.time} Minutes</div>
    </div>
  )
}

// GRAPH
const CalendarGraph = (props) => {
  const date = new Date()
  const thisMonth = date.getMonth()

  return (
    <div className="Graph CalendarGraph">
      <div className="CalendarGraphContent">
        <div className="columns is-multiline is-mobile ">
          {data.map((month, index) =>
            <Month
              key={index}
              month={month.month}
              days={month.days}
              starts={month.starts}
              planned={month.planned}
              active={thisMonth === index && true}
            />
          )}
        </div>
      </div>

      <div className="GraphStats">
        <div className="GraphStatsContent">
          <div className="columns is-mobile is-multiline">
            <div className="column is-6-mobile is-6-tablet is-3-desktop">
              <h2 className="title is-4 GraphStatTitle">Time in gym</h2>
              <h3 className="title is-2 is-thin">2h 45m</h3>
            </div>
            <div className="column is-6-mobile is-6-tablet is-3-desktop">
              <h2 className="title is-4 GraphStatTitle">Burned</h2>
              <h3 className="title is-2 is-thin">1802Cal</h3>
            </div>
            <div className="column is-6-mobile is-6-tablet is-3-desktop">
              <h2 className="title is-4 GraphStatTitle">Weight lost</h2>
              <h3 className="title is-2 is-thin">0.5kg</h3>
            </div>
            <div className="column is-6-mobile is-6-tablet is-3-desktop">
              <h2 className="title is-4 GraphStatTitle">Muscle gained</h2>
              <h3 className="title is-2 is-thin">0.1kg</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Month = (props) => {
  const allDays = props.days
  const allDaysCount = props.days.length
  const startDays = [...Array(props.starts)]
  const startDaysCount = props.starts
  const leftDays = [...Array((35 - startDaysCount) - allDaysCount)];

  const classes = classNames({
    "Month": true,
  })

  const date = new Date()
  const today = date.getDate()
  // console.log(date.getMonth());

  return (
    <div className="column is-half-mobile is-half-tablet is-one-quarter-desktop">
      <h2 className="title is-7 MonthTitle">{props.month}</h2>
      <div className={classes}>
        {startDays.map((amount, index) =>
          <Day key={index} inactive>
            <div className="DayContent">
            </div>
          </Day>
        )}

        {allDays.map((day, index) =>  
          <Day
            key={index}
            success={day.active}
            planned={day.planned}
            today={props.active && day.day === today && true}
          >
            {
              day.active ?
              <Tippy
                content={<CalendarTooltip type={day.type} calories={day.calories} time={day.time} />}
                hideOnClick={false}
                followCursor={true}
                placement="bottom"
                animation="scale"
                distance="8"
                theme="light"
              >
                <div className="DayContent">
                  
                </div>
              </Tippy>
              :
              <div className="DayContent">
              </div>
            }
          </Day>
        )}

        {leftDays.map((amount, index) =>
          <Day key={index} inactive>
            <div className="DayContent">
            </div>
          </Day>
        )}

        
      </div>
    </div>
  )
}

const Day = (props) => {
  const classes = classNames({
    "Day": true,
    "is-success": props.success,
    "is-disabled": props.inactive,
    "is-today": props.today,
    "is-planned": props.planned,
  })
  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}

export default CalendarGraph;


