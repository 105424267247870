import React, {useState, useEffect} from 'react'

import classNames from 'classnames'

// COMPS
import Card from 'components/Card/Card'

// Card
const TimeCard = (props) => {
  const [time, setTime] = useState(new Date());
  console.log(time.getMonth())

  const tick = () => {
    setTime(new Date())
  }

  useEffect(() => {
    setInterval(() => {
      tick()
    }, 1000)
  }, [])

  return (
    <Card type="time" header={false} {...props}>
      <div className="TimeCardTimer">
        <div className="TimeCardDigit TimeCardHour">
          {time.getHours()}
        </div>
        <div className="TimeCardDigit TimeCardSeconds">
          :
        </div>
        <div className="TimeCardDigit TimeCardMinutes">
          {(time.getMinutes() < 10 ? '0' : '') + time.getMinutes()}
        </div>
      </div>
    </Card>
  )
}

TimeCard.defaultProps = {
  photo: undefined, // defaults to image default
}


export default TimeCard;