import React from 'react'

// CSS
import './Loader.scss'

// COMPS
import classNames from 'classnames'

// ACTIONS
const Loader = (props) => {

  const classes = classNames({
    "Loader": true,
    "is-centered": props.absolute,
    "has-error": props.error,
    "has-no-spinner": props.spinner === false,
  })

  return (
    <div className={classes}>
      <div className="Spinner">
        {/* Empty */}
      </div>
    </div>
  )
}

export default Loader;