import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

// CSS
import 'css/global.scss'
import './Site.scss'

// COMPS
import Image from 'components/Image/Image'


// PAGES
import Today from 'pages/Today'
import Error from 'pages/Error'
import Inspiration from 'pages/Inspiration'
import Retorio from 'pages/Retorio'
import Auth from 'pages/Auth'

// SITE

const Site = (props) => {
  return (
    <Router>
      <>
        <Helmet
          titleTemplate="%s - Dashboard"
          defaultTitle="Dashboard"
        >
          <meta charSet="utf-8" />
        </Helmet>
        <Switch>
          <Route exact path={`/`} component={Today} />
          <Route path={`/space/:id`} component={Inspiration} />
          <Route path="/project/:id" component={Retorio} />
          <Route path="/health/:id" component={Retorio} />
          <Route path="/signup" component={Auth} />
          <Route component={Error} />
        </Switch>
      </>
    </Router>
  )
}

export default Site;