import React from 'react'
import Layout from 'layout/Layout'



// ACTIONS
const ErrorPage = (props) => {
  return (
    <Layout>
      <p>Quote</p>
    </Layout>
  )
}

export default ErrorPage;