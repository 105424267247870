import React from 'react'
import classNames from 'classnames'

// CSS
import './Icon.scss'

// COMPS
const settings = {
  color: "currentColor",
  fill: "currentFill",
  viewBox: {
    tiny: "0 0 16 16",
    small: "0 0 24 24",
    default: "0 0 32 32",
    large: "0 0 48 48"
  }
}

// PARENT

const IconParent = (props) => {
  const classes = classNames({
    "Icon": true,
    "is-large": props.size === "large",
    "is-small": props.size === "small",
    "is-tiny": props.size === "tiny",
  })


  return (
    <svg viewBox={props.viewBox} className={classes} {...props}>
      {props.children}
    </svg>
  )
}

// ICON
const Icon = {
  Avatar: function Avatar(props) {
    return (
      <IconParent {...props}>
        <defs>
          <rect id="shape" x={0} y={0} width={32} height={32} rx={16} opacity="50%" />
          <path
            d="M28.24 26.307A15.965 15.965 0 0 1 16 32a15.96 15.96 0 0 1-11.861-5.26c.242-.215.519-.395.824-.533 2.19-.99 6.15-2.332 11.537-2.332 5.387 0 9.347 1.342 11.537 2.332.07.031.136.065.202.1zM16.5 20.5a6.75 6.75 0 1 1 0-13.5 6.75 6.75 0 0 1 0 13.5z"
            id="person"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <use fill={settings.color} xlinkHref="#shape" opacity="0.2" />
          <use fill={settings.color} xlinkHref="#person" />
        </g>
      </IconParent>
    );
  },
  Add: function Add(props) {
    return (
      <IconParent {...props}>
        <path
          d="M17 15h8.008c.548 0 .992.444.992 1 0 .552-.455 1-.992 1H17v8.008A.993.993 0 0 1 16 26c-.552 0-1-.455-1-.992V17H6.992A.993.993 0 0 1 6 16c0-.552.455-1 .992-1H15V6.992A.993.993 0 0 1 16 6c.552 0 1 .455 1 .992V15z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Search: function Search(props) {
    return (
      <IconParent {...props}>
        <path
          d="M27 27l-5.6-5.6L27 27zm-12-3a9 9 0 1 1 0-18 9 9 0 0 1 0 18z"
          stroke={settings.color}
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"/>
      </IconParent>
    );
  },
  Profile: function Profile(props) {
    return (
      <IconParent {...props}>
        <path
          d="M16 4C9.383 4 4 9.383 4 16s5.383 12 12 12 12-5.383 12-12S22.617 4 16 4zm6.698 19.404C22.034 21.431 20.198 20 18 20h-4c-2.198 0-4.032 1.432-4.696 3.405C7.281 21.574 6 18.937 6 16c0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.936-1.28 5.573-3.302 7.404zM16 9a4 4 0 0 0-4 4v1a4 4 0 0 0 8 0v-1a4 4 0 0 0-4-4z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Pay: function Pay(props) {
    return (
      <IconParent {...props}>
        <path
          d="M27 9h-4c0-2.2-1.8-4-4-4h-6c-2.2 0-4 1.8-4 4H6c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h3.1c.5 3.9 3.9 7 7.9 7s7.4-3.1 7.9-7H27c.6 0 1-.4 1-1V10c0-.6-.4-1-1-1zM13 7h6c1.1 0 2 .9 2 2H11c0-1.1.9-2 2-2zm4 8c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm6 4.8v.4c-.1 3.2-2.7 5.8-6 5.8-3 0-5.4-2.2-5.9-5h5c-.1.3-.1.7-.1 1 0 .6.4 1 1 1s1-.4 1-1c0-1.2.9-3 2-3 .6 0 1-.4 1-1v-2c0-.7.7-2 2-2v5.8z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Cog: function Cog(props) {
    return (
      <IconParent {...props}>
        <path
          d="M25 14c1.1 0 2 .9 2 1.9 0 1.1-.9 2-2 2h-1.1c-.2.8-.5 1.5-.9 2.1l.9.9c.8.8.8 2 0 2.8-.8.8-2 .8-2.8 0l-.9-.9c-.7.4-1.4.7-2.1.9V25c0 1.1-.9 2-2 2s-2-.9-2-2v-1.2c-.8-.2-1.5-.5-2.1-.9l-.9.9c-.8.8-2 .8-2.8 0-.8-.8-.8-2 0-2.8l.9-.9c-.4-.7-.7-1.4-.9-2.1H7c-1.1 0-2-.9-2-2s.9-2 2-2h1.3c.2-.7.4-1.4.9-2.1l-.9-.9c-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0l.8.9c.7-.4 1.4-.6 2-.8V7c0-1.1.9-2 2-2s2 .9 2 2v1.2c.8.2 1.5.5 2.1.9l.9-.9c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-.9.9c.4.7.7 1.4.9 2.1H25zm-9 5c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Attachment: function Attachment(props) {
    return (
      <IconParent {...props}>
        <path
          d="M12.5 27.175c-1.9 0-3.8-.7-5.3-2.2-1.4-1.4-2.2-3.3-2.2-5.3s.8-3.9 2.2-5.3l7.8-7.8c2.1-2.1 5.6-2.1 7.8 0s2.1 5.6 0 7.8l-7.1 7.1c-1.4 1.4-3.6 1.4-5 0s-1.4-3.6 0-5l6-6c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-6 6c-.6.6-.6 1.5 0 2.1.6.6 1.5.6 2.1 0l7.1-7.1c.7-.7 1-1.5 1-2.5 0-.9-.4-1.8-1-2.5-1.4-1.4-3.6-1.4-4.9 0l-7.8 7.8c-1 1-1.6 2.4-1.6 3.9s.6 2.8 1.6 3.9c2.1 2.1 5.6 2.1 7.8 0l8.8-8.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8.8 8.8c-1.4 1.6-3.3 2.3-5.3 2.3z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Chat: function Chat(props) {
    return (
      <IconParent {...props}>
        <path
          d="M16.5 6C10.149 6 5 10.12 5 15.2c0 2.067.862 3.968 2.3 5.503V26.7l5.558-2.778c1.145.305 2.368.478 3.642.478 6.351 0 11.5-4.12 11.5-9.2C28 10.12 22.851 6 16.5 6z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Tick: function Tick(props) {
    return (
      <IconParent {...props}>
        <path
          d="M6 16l2-2 5 3 11-8 2 2-13 13z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Note: function Note(props) {
    return (
      <IconParent {...props}>
        <path
          d="M24 6H8a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zm-7 15h-6v-2h6v2zm4-4H11v-2h10v2zm0-4H11v-2h10v2z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Email: function Email(props) {
    return (
      <IconParent {...props}>
        <path
          d="M19 27c-.377 0-.725-.214-.895-.554l-2.968-5.936a1.004 1.004 0 0 1 .072-1.017L19.999 13l-6.494 4.79a1.004 1.004 0 0 1-1.017.072l-5.935-2.967a1.002 1.002 0 0 1 .1-1.833l19-7a1 1 0 1 1 1.284 1.284l-7 19A.998.998 0 0 1 19 27z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },

  // SMALL

  Boxing: function Boxing(props) {
    return (
      <IconParent {...props} size="tiny" viewBox={settings.viewBox.tiny}>
        <path
          d="M13.74 7.2c.645 1.12.106 2.557-1.125 3.001l-4.988 1.8-1.818 1.048a1.39 1.39 0 0 1-1.901-.495L2.183 9.566a1.39 1.39 0 0 1 .521-1.895l1.274-.735 1.238-3.152a1.412 1.412 0 0 1 1.806-.806l.354.134L6.182 5.22l2.392-2.019c1-.844 2.515-.591 3.16.527l2.005 3.473z" 
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Strength: function Strength(props) {
    return (
      <IconParent {...props} size="tiny" viewBox={settings.viewBox.tiny}>
        <path
          d="M13.163 3.947l.928 1.608-.703.407.928 1.608c.257.444.15.986-.24 1.21-.388.225-.91.047-1.167-.398l-.929-1.608-7.036 4.063.928 1.608c.257.444.15.986-.239 1.21-.389.225-.911.047-1.168-.398l-.928-1.608-.704.406-.929-1.608.704-.406-.929-1.609c-.256-.444-.149-.986.24-1.21.388-.224.911-.046 1.168.398l.928 1.608 7.037-4.062-.929-1.609c-.256-.444-.149-.986.24-1.21.388-.224.911-.046 1.168.398l.928 1.608.704-.406z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
  Squash: function Squash(props) {
    return (
      <IconParent {...props} size="tiny" viewBox={settings.viewBox.tiny}>
        <path
          d="M12.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM9.504 9.704c0 .242.036.474.091.7a4.336 4.336 0 0 1-.823.091 3.42 3.42 0 0 1-1.743-.461l-.802.802a.744.744 0 0 1-.144.844l-2.102 2.1a.748.748 0 0 1-1.06 0l-.701-.7a.75.75 0 0 1 0-1.06L4.32 9.919a.745.745 0 0 1 .845-.144l.793-.793c-.391-.659-.536-1.47-.41-2.35.157-1.086.716-2.157 1.575-3.014.858-.858 1.93-1.417 3.016-1.574 1.174-.167 2.217.143 2.948.874.986.985 1.162 2.538.58 4.02a3.004 3.004 0 0 0-4.164 2.765z"
          fill={settings.color}
          fillRule="evenodd"
        />
      </IconParent>
    );
  },
}


// DEFAULTS

IconParent.defaultProps = {
  viewBox: settings.viewBox.default,
}

export default Icon;