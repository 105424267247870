import React from 'react'

// CSS


// COMPS
import Image from 'components/Image/Image'
import HasLink from 'components/Link/HasLink'
import Card from 'components/Card/Card'

// Card
const PhotoCard = (props) => {
  return (
    <Card type="photo" header={false} {...props}>
      <HasLink to={props.to} className="PhotoCardLink"><Image src={props.photo} spinner={props.spinner} /></HasLink>
    </Card>
  )
}

PhotoCard.defaultProps = {
  photo: undefined, // defaults to image default
}


export default PhotoCard;