import React from 'react'

// CSS


// COMPS
import Card from 'components/Card/Card'
import Icon from 'components/Icon/Icon'

// Card
const ActionsCardItem = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
  }

  return (
    <a href="/" className="ActionCardItem" onClick={handleClick}>
      <div className="ActionCardItemIcon">{props.icon}</div>
      <div className="ActionCardItemLabel">{props.label}</div>
    </a>
  )
}

// Card
const ActionsCard = (props ) => {
  return (
    <Card type="actions" header={false} {...props}>
      <div className="ActionCardItems">
        <ActionsCardItem
          icon={<Icon.Search />}
          label="Search in google"
        />
        <ActionsCardItem
          icon={<Icon.Pay />}
          label="Send money"
        />
        <ActionsCardItem
          icon={<Icon.Profile />}
          label="Add person"
        />
        <ActionsCardItem
          icon={<Icon.Note />}
          label="New note"
        />
        <ActionsCardItem
          icon={<Icon.Email />}
          label="Send email"
        />
        <ActionsCardItem
          icon={<Icon.Chat />}
          label="New chat"
        />
      </div>
      
    </Card>
  )
}

// CardHeader.defaultProps = {
//   title: "Something"
// }

export default ActionsCard;