import React from 'react'
// CSS
import classNames from 'classnames'
import './Card.scss'

// CARD
const Card = ({type, title, children, header, padding, ...props}) => {
  const isActions = type === "actions"
  const isNotes = type === "notes"
  const isPhoto = type === "photo"
  const isTime = type === "time"
  const isGraph = type === "graph"

  
  const classes = classNames({
    "Card": true,
    "is-dark": props.dark,
    "ActionCard": isActions,
    "NotesCard": isNotes,
    "PhotoCard": isPhoto,
    "GraphCard": isGraph,
    "TimeCard": isTime,
    "grid-item": props.grid,
    [`grid-row-${props.row}`]: props.row,
    [`grid-col-${props.col}`]: props.col,
  })
  

  return (
    <div className={classes}>
      { header && 
        <CardHeader title={title}></CardHeader>
      }
      <CardContent padding={padding} style={CSS}>
        {children}
      </CardContent>
    </div>
  )
}

// HEADER

const CardHeader = ({ title, ...props }) => {
  return (
    <header className="CardHeader">
      {title}
    </header>
  )
}


// CONTENT

const CardContent = ({padding, ...props}) => {
  const classes = classNames({
    "CardContent": true,
    "has-no-padding": padding === false,
  })

  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}


Card.defaultProps = {
  title: "Something",
  header: true,
}

export default Card;