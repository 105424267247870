import React from 'react'

// CSS
import './Main.scss'

// MAIN
const Main = (props) => {
  return (
    <main className="Main">
      {props.children}
    </main>
  )
}

export default Main;