import React from 'react'



// ACTIONS
const AuthPage = (props) => {
  return (
    <p>Auth</p>
  )
}

export default AuthPage;