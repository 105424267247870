import React from 'react'
import { Helmet } from 'react-helmet'

// COMPS
import Layout from 'layout/Layout'

import CalendarGraph from 'components/Graph/CalendarGraph'

import PhotoCard from 'components/Card/PhotoCard'
import ActionsCard from 'components/Card/ActionsCard'
import GraphCard from 'components/Card/GraphCard'
import TimeCard from 'components/Card/TimeCard'

// ACTIONS
const Today = (props) => {
  return (
    <Layout>
      <div className="grid">
        <Helmet
          bodyAttributes={{
            class: 'is-page-today'
          }}
        >
          <title>Today</title>
        </Helmet>
        <ActionsCard />
        <GraphCard
          title="Gym visits"
          col="2"
        >
          <CalendarGraph>
            
          </CalendarGraph>
        </GraphCard>

        <PhotoCard
          col="2"
        />

        <TimeCard dark></TimeCard>
      </div>
    </Layout>
  )
}

export default Today;