import React from 'react'
import Link from "components/Link/Link";



// CSS
import './Navigation.scss'

// COMPS
import Icon from 'components/Icon/Icon'

// ACTIONS
const Actions = (props) => {
  return (
    <div className="Navigation">
      <div className="container">
        <div className="level is-mobile">
          <aside className="level-left NavigationMenu">
            <ul className="NavigationMenuList">
              <li className="NavigationMenuListItem">
                <Link to="/">Today</Link>
              </li>
              <li className="NavigationMenuListItem">
                <Link to="/space/inspiration">Inspiration</Link>
              </li>
              <li>
                <div className="NavigationMenuListGroupHeader">Health</div>
                <ul className="NavigationMenuListGroup">
                  <li className="NavigationMenuListItem">
                    <Link to="/health/general">General</Link>
                  </li>
                  <li className="NavigationMenuListItem">
                    <Link to="/health/weight">Weight</Link>
                  </li>
                </ul>
              </li>
              <li>
                <div className="NavigationMenuListGroupHeader">Projects</div>
                <ul className="NavigationMenuListGroup">
                  <li className="NavigationMenuListItem">
                    <Link to="/project/retorio">Retorio</Link>
                  </li>
                  <li className="NavigationMenuListItem">
                    <Link to="/project/academy">Academy</Link>
                  </li>
                  <li className="NavigationMenuListItem">
                    <Link to="/project/dashboard">Dashboard</Link>
                  </li>
                </ul>
              </li>
              {/* <li>
                <div className="NavigationMenuListGroupHeader">Profile</div>
                <ul className="NavigationMenuListGroup">
                  <li className="NavigationMenuListItem">
                    <Link to="#" className="modal-trigger" data-target="modal-signout">Sign out</Link>
                  </li>
                  <li className="NavigationMenuListItem">
                    <Link to="#" className="modal-trigger" data-target="modal-settings">Settings</Link>
                  </li>
                </ul>
              </li> */}
              
            </ul>
          </aside>

          <div className="level-right NavigationActions">
            <Link to="/" className="NavigationActionsAdd">
              <Icon.Add />
            </Link>
          </div>

        </div>
      </div>
      
    </div>
  )
}

export default Actions;