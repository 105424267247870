import React from 'react'
import { NavLink } from 'react-router-dom'

const Link = ({ to, children, ...props }) => {
  const activeClassName = "is-active"

  return (
    <NavLink to={to} exact {...props} activeClassName={activeClassName}>{children}</NavLink>
  )
}

export default Link;