import React from 'react'

// CSS

// COMPS
import Navigation from './Navigation/Navigation'
import Main from './Main/Main'
import Image from 'components/Image/Image'

// LAYOUT
const Layout = (props) => {

  return (
    <>
      <div className="wrapper">
        <div className="container">
          <div className="Site">
            <Navigation />
            <Main>
              {props.children}
            </Main>
          </div>
        </div>
      </div>
      {
        props.showPhoto &&
        <div className="SiteBg">
          <Image src={props.photo} spinner={false} />
        </div>
      }
      
    </>
  )
}

Layout.defaultProps = {
  showPhoto: true,
  photo: "https://source.unsplash.com/collection/3770701/3200x1800",
}

export default Layout;